#block-and-flag {
  margin-top: 40px;
}

.block-btn {
  margin-right: 40px;
}

.flag-btn {
  margin-right: 40px;
}
