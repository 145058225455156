#CollusionGroup {
  padding: 0 32px 32px;
}

.title {
  font-size: 36px;
  font-weight: 300;
  text-align: left;
  margin-top: 40px;
  grid-column: 1 / span 2;
}

.cta {
  margin-top: 32px;
  text-align: left;
}

.error,
.success {
  margin-bottom: 8px;
  font-weight: 600;
}

.error {
  color: red;
}

.success {
  color: green;
}
