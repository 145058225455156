#cf-sessions {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(5, auto);
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.th,
.td {
  text-align: left;
  word-break: break-all;
}

.th {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.td {
  padding: 30px 10px 10px 10px;
}

.details {
  grid-column: 1 / -1;
  text-align: left;
  background: #fafafa;
  padding: 40px;
  border-radius: 8px;
}

.key {
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  color: #8b8b8b;
}

.value {
  font-size: 16px;
}
