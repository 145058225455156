.refund-details {
  padding: 40px;
}

.list-item {
  text-align: left;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 24px;
}

.key {
  font-size: 0.75rem;
  color: #8b8b8b;
  font-weight: bold;
}

.value {
  font-size: 1.75rem;
  font-weight: 300;
}

.first {
  margin: 20px 0;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}
