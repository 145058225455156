#CSVUpload {
  padding: 40px;
}

.header {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.cta {
  display: flex;
}

.cta input {
  height: max-content;
  border: navajowhite;
}

.table {
  display: grid;
  grid-template-columns: repeat(5, auto);
  margin-top: 50px;
}

.th {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: uppercase;
  text-align: left;
}

.td {
  padding: 36px 0;
  text-align: left;
}

.download {
  cursor: pointer;
}

.download svg {
  width: 24px;
  height: 24px;
  color: #728ee3;
}
