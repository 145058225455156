#GameHistory {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(7, auto);
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.value,
.head {
  text-align: left;
}

.value {
  padding: 30px 10px 10px 10px;
  word-break: break-all;
}

.head {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.RightArrow {
  cursor: pointer;
}

.StatusComplete {
  color: green;
}

.StatusOngoing {
  color: orange;
}

.Game {
  display: flex;
  align-items: center;
}

.GameIcon img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.GameName {
  margin-left: 20px;
}
