#pass-transactions {
  padding: 40px;
  display: grid;
  grid-template-columns: auto repeat(4, 1fr) 200px 200px auto;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.value,
.head {
  text-align: left;
}

.value {
  padding: 30px 10px 10px 10px;
  word-break: break-all;
}

.head {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.value img {
  cursor: pointer;
}

.credit {
  color: #45b69b;
}

.debit {
  color: #e95656;
}

.no-pass-transactions {
  grid-column: 1 / -1;
  padding: 64px;
  color: #ddbbaa;
  font-size: 1.5rem;
}
