.value {
  padding: 30px 10px 10px 10px;
  text-align: left;
  word-break: break-all;
}

.quick-actions {
  padding: 30px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
}

.quick-actions svg {
  cursor: pointer;
}
