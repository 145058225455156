#top-played-games {
  padding: 40px;
  overflow-y: auto;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}
.table {
  display: grid;
  grid-template-columns: 84px repeat(2, auto);
  text-align: left;
}

.th {
  color: #8b8b8b;
  font-size: 0.75rem;
  font-weight: bold;
  background: rgba(216, 216, 216, 0.2);
  padding: 10px;
  margin-top: 20px;
}

.td {
  word-break: break-all;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.game-icon img {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.no-games {
  padding: 64px;
  font-size: 1.5rem;
  color: #dba;
}
