#wallets {
  padding: 40px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title > span {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}

.title button {
  text-transform: uppercase;
  color: #728ee3;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.wallets-list,
.passes-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.wallets-list {
  margin-bottom: 40px;
}

.list-item {
  text-align: left;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 24px;
}

.key {
  font-size: 0.75rem;
  color: #8b8b8b;
  font-weight: bold;
}

.value {
  font-size: 28px;
  font-weight: 300;
}

:global(.move-money-modal .ant-modal) {
  width: 768px !important;
}

:global(.move-money-modal .ant-modal-content) {
  border-radius: 36px;
}

:global(.move-money-modal .ant-modal-body) {
  padding: 60px 72px 44px;
}

.modal-title {
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 38px;
  margin-bottom: 42px;
}

.modal-label {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 9px;
  margin-top: 27px;
}

.modal-label:first-child {
  margin-top: 0;
}

:global(.move-money-modal select),
:global(.move-money-modal textarea) {
  cursor: not-allowed;
  width: 100%;
  height: 42px;
  border: 2px solid #728ee3;
  border-radius: 8px;
  color: #728ee3;
  font-weight: bold;
  padding: 8px 16px;
  outline: none;
}

:global(.move-money-modal textarea) {
  height: 116px;
  cursor: text;
}

.modal-cta {
  margin-top: 80px;
}

.modal-confirm {
  margin-right: 41px;
  background-color: #728ee3;
  border-color: #728ee3;
  border-radius: 8px;
}

.modal-btn {
  font-weight: 600;
  height: 40px;
}

.modal-error {
  color: red;
  margin-bottom: 8px;
}
