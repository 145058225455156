#all-transactions {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(4, auto) 300px auto auto;
}

.value,
.head {
  text-align: left;
  word-break: break-all;
}

.value {
  padding: 30px 10px 10px 10px;
}

.head {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.value img {
  cursor: pointer;
}

.red {
  color: #e95656;
}
.green {
  color: #45b69b;
}

.Complete {
  color: #45b69b;
}
.Processing,
.Pending {
  color: #fa9f41;
}
.Failed {
  color: #e95656;
}

.no-transactions,
.lastPage {
  grid-column: 1 / -1;
  padding-top: 40px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

:global(.all-transactions .ant-spin.ant-spin-spinning.ant-spin-show-text) {
  position: fixed;
  max-height: unset;
  pointer-events: none;
}
