#customer-support-reward {
  /* padding: 40px; */
}

.key {
  color: #8b8b8b;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 20px;
}
.key:nth-child(1) {
  margin-top: 0;
}

.value > div {
  width: 100%;
}

.error {
  margin-top: 20px;
  color: red;
}
