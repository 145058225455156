#general-details {
  padding: 40px;
}

.name {
  font-size: 28px;
}

.address {
  font-size: 16px;
  color: #8b8b8b;
}

.details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 40px;
}

.player-dp {
  margin-top: -128px;
}

.photo {
  height: 160px;
  width: 160px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 80px;
}

.location {
  width: 18px;
  padding-bottom: 4px;
  filter: invert(50%);
}
