#kyc {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(6, auto);
}

.head {
  font-size: 0.75rem;
  color: #8b8b8b;
  padding: 10px;
  text-align: left;
}

.cell {
  padding: 10px;
  text-align: left;
}

.Complete {
  color: #45b69b;
}
.Manual_Supervision {
  color: #fa9f41;
}
.Failed {
  color: #e95656;
}

.details {
  grid-column: span 7;

  padding: 40px;
  text-align: left;
}

.wild-upload {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.wild-upload button {
  padding: 0;
}

.no-kyc,
.kyc-details-error {
  grid-column: 1 / -1;
  padding: 64px;
  font-size: 1.5rem;
  color: #dba;
}
