.StaggeredDetails {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.card {
  height: 370px;
  background: #fafafa;
  border-radius: 24px;
  padding: 40px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
}

.title {
  font-size: 28px;
  font-weight: 300;
}
