.card {
  height: 370px;
  background: #fafafa;
  border-radius: 24px;
  padding: 40px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
}

.title {
  font-size: 28px;
  font-weight: 300;
}

.key {
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  color: #8b8b8b;
}

.value {
  font-size: 16px;
  word-break: break-all;
}

.wallets {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.refund {
  margin-top: 20px;
}

.th {
  margin-top: 20px;
  background: rgba(216, 216, 216, 0.2);
  padding: 12px;
  color: #8b8b8b;
  font-size: 0.75rem;
}

.td {
  padding: 12px;
}

.error {
  color: red;
  font-size: 1.1rem;
  margin-top: 20px;
  padding-top: 10px;
}

.close-session {
  margin-top: 20px;
}

.process-refund {
  padding: 10px;
  margin-top: 40px;
}

.linked-rewards {
  margin-top: 40px;
}

.linked-orders {
  display: grid;
  grid-template-columns: repeat(3, auto);
}
