#orders-table {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(6, auto);
  height: fit-content;
}

.player-btn {
  padding: 0;
}

.value,
.head {
  text-align: left;
  word-break: break-all;
}

.value {
  padding: 30px 10px 10px 10px;
}

.head {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.lastPage {
  grid-column: 1 / -1;
  padding-top: 40px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

.loader {
  background: red;
}

.filters {
  grid-column: 1/-1;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

.header {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.applied-filters {
  grid-column: 1 / -1;
  margin-bottom: 20px;
  text-align: left;
}

.filter-key {
  font-weight: bold;
}
