.StaggeredPreview {
  padding: 40px;
  border-radius: 8px;
  background-color: #fafafa;
  margin-top: 12px;
  width: unset;
}

.stp-title {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 28px;
}

.table {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 8px;
}

.th {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: uppercase;
}

.td {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.amount {
  text-align: right;
}

.separator {
  grid-column: 1/-1;
  background-color: #8b8b8b;
  height: 1px;
  opacity: 0.2;
  margin: 20px 0;
}

.total {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
}

.total-text {
  margin-right: 46px;
}
