#deposit-offers {
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.title {
  font-size: 36px;
  font-weight: 300;
  text-align: left;
  margin-top: 40px;
  grid-column: 1 / span 2;
}

.subtitle {
  font-size: 28px;
  margin-top: 40px;
  font-weight: 300;
  text-align: start;
}

.create-offer-form {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.create-offer-form > * {
  width: 322px;
}

.create-offer-form > input,
.create-offer-form > select,
.create-offer-form > textarea,
.create-offer-form > :global(.ant-picker),
.details-array > input {
  width: 322px;
  border: 2px solid #728ee3;
  border-radius: 8px;
  padding: 8px;
}

.details-array > input {
  width: 300px;
  margin-top: 4px;
}

.details-array > :global(.ant-btn) {
  padding-right: 0;
  font-weight: bold;
}

.create-offer-form > span {
  margin-top: 20px;
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.create-offer-btn {
  margin-top: 16px;
  padding: 8px;
  cursor: pointer;
  border: none;
  background: #3390ff;
  color: white;
  font-weight: bold;
}

.create-offer-btn:disabled,
.create-audience-btn:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.error {
  margin-top: 20px;
  padding: 4px;
  color: red;
  font-weight: bold;
}

.add-detail-btn {
  padding-left: 0;
  margin-top: 4px;
}

.field-error {
  color: red;
}
