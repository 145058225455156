#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #728ee3;
}

.notifications {
  padding: 0 24px 0 0;
}

.notifications-icon {
  margin-right: 4px;
}

.user {
  padding: 0 12px;
  cursor: pointer;
}

.user-dp {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 4px;
}

.right {
  display: flex;
  align-items: center;
}

.left {
  padding-left: 8px;
  font-weight: bold;
}

.left a {
  color: #728ee3;
}

.down-arrow {
  width: 16px;
  padding-left: 2px;
}

.MenuItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
