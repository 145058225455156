.MSOrderDetails {
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.card {
  height: 370px;
  background: #fafafa;
  border-radius: 24px;
  padding: 40px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
}

.title {
  font-size: 28px;
  font-weight: 300;
}

.key {
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  color: #8b8b8b;
}

.value {
  font-size: 16px;
  word-break: break-all;
}

.wallets {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.th {
  margin-top: 20px;
  background: rgba(216, 216, 216, 0.2);
  padding: 12px;
  color: #8b8b8b;
  font-size: 0.75rem;
}

.td {
  padding: 12px;
}
