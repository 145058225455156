.title {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.checks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.head {
  padding: 10px;
  background: #fafafa;
  color: #8b8b8b;
  font-size: 0.75rem;
}

.body {
  padding: 10px;
}

.passed {
  color: #45b69b;
}
.failed {
  color: #e95656;
}

.checks {
  margin-bottom: 40px;
}

.images {
  padding: 10px;
}

.images {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.document-image {
  height: 220px;
  width: 382px;
}

.details-by {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 40px;
}

.details-by-ocr {
  grid-row: span 2;
}

.details-by-ocr,
.details-by-user,
.accept-reject {
  padding: 40px;
  background: #fafafa;
  border-radius: 24px;
}

.key {
  color: #8b8b8b;
  text-transform: uppercase;
  font-size: 0.75rem;
}
.value {
  margin-bottom: 20px;
}

.select {
  width: 100%;
}

.accept-reject > div {
  margin-bottom: 40px;
}

.img-btn {
  padding: 0;
}
