.search {
  margin-top: 80px;
}

.text {
  font-size: 36px;
}

.search-input-div {
  margin-top: 16px;
}

.search-input {
  width: 600px;
  border-radius: 20px;
}

:global(.ant-input-search-button) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.player-not-found {
  width: fit-content;
  margin: 20px auto;
  text-align: left;
}

:global(.ant-alert-message) {
  color: #ff4d4f;
}
