#blocking-details {
  padding: 40px;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 32px;
}

#blocking-details table {
  width: 100%;
  text-align: left;
}

#blocking-details th {
  font-size: 0.75rem;
  color: #8b8b8b;
}

#blocking-details td {
  font-size: 0.75rem;
  margin-bottom: 20px;
  padding: 16px 0;
}
