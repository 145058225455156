#player-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 40px;
  margin-top: 80px;
}

.card {
  border-radius: 40px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}

.general-details,
.wallets,
.blocking-details {
  grid-column: span 2;
}

.block-banner {
  grid-column: span 2;
  display: flex;
  position: relative;
  bottom: 70px;
  align-items: center;
  justify-content: center;
  background: rgb(224 32 32 / 0.3);
  border-radius: 8px;
}

.block-banner-text {
  color: #e02020;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  top: 8px;
}

.block-banner-button {
  color: #728ee3;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  outline: none;
  border: none;
  background: none;
  margin-left: 10px;
}

:global(.player-summary .ant-card-body) {
  padding: 0;
}
