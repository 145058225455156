.personal-details {
  padding: 40px;
  background: #fafafa;
  border-radius: 24px;
  text-align: left;
}

.title {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.key {
  font-size: 0.75rem;
  color: #8b8b8b;
}

.value {
  font-size: 1rem;
  margin-bottom: 20px;
}

.kyc-value {
  display: flex;
  justify-content: space-between;
}

.upi {
  margin-top: 10px;
  margin-bottom: 20px;
}
