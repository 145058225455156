#offers-list {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(8, auto);
  height: fit-content;
}

.header {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.value,
.head {
  text-align: left;
  word-break: break-all;
}

.head {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
  font-weight: 600;
}

.first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.no-offers {
  grid-column: 1 / -1;
  padding: 64px;
  text-align: center;
  font-size: 20px;
  color: red;
}
