.modalH {
  margin-bottom: 16px;
  color: #253162;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.actionsGroup {
}

.radio {
  margin-right: 32px;
}

.selectTitle {
  width: 160px;
}

.sectionDivider{
  margin-top: 35px;
}

.selectDesc {
  width: 500px;
}