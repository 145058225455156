.cards {
  width: 100%;
  margin-top: 40px;

  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-around;
  row-gap: 40px;
}

.card {
  height: 180px;
  width: 430px;
  border-radius: 40px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}

:global(.ant-card-body) {
  height: 100%;
}

.kyc,
.users,
.game-schedule,
.offers-admin,
.player-profiles,
.mtt-admin {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.multiple-btns-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.text {
  text-align: left;
  font-weight: 600;
}

.btn-div {
  text-align: right;
  width: 100%;
}

.main-text {
  font-size: 28px;
}

.sub-text,
.btn {
  font-size: 16px;
}

.btn {
  font-weight: 600;
}

.new-indicator {
  position: absolute;
  right: 4px;
  top: 4px;
}
