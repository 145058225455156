.firestore-filters {
  padding: 40px;
}

.filters {
  /* display: grid; */
  /* grid-template-columns: auto; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter-div {
  text-align: left;
  margin-right: 20px;
  min-width: 300px;
}
.last {
  margin-right: 0;
}

:global(.filter-div .ant-picker),
:global(.filter-div .ant-select) {
  min-width: 100%;
}

.actions {
  text-align: left;
  margin-top: 20px;
}
