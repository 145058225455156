#UploadPopup {
  position: fixed;
  bottom: 0;
  right: 40px;
  width: 400px;
  text-align: left;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background: white;
  z-index: 1;
}

.header {
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.375rem;
  background: #000;
  padding: 8px 16px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  padding: 24px 16px;
}

.filename,
.errorHeader {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.375rem;
  margin-bottom: 25px;
}

.errorHeader {
  margin-bottom: 8px;
  color: #e02020;
}

.offersCount {
  color: #8b8b8b;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.0625rem;
  text-transform: uppercase;
  margin-bottom: 4px;
}

progress {
  width: 100%;
  margin-bottom: 20px;
}

.errorHelp {
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.0625rem;
  margin-bottom: 25px;
}

.download,
.download a,
.successHeader {
  color: #728ee3;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.375rem;
}

.downloadIcon {
  margin-right: 10px;
}

.close {
  cursor: pointer;
}

.successHeader {
  color: #45b69c;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filenameSuccess {
  color: #45b69c;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.0625rem;
}

.checkSvg svg {
  height: 34px;
  width: 34px;
  color: #00b440;
}
