#campaign {
  height: 500px;
  padding: 40px;
}

.title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}

.coming-soon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: orange;
}
