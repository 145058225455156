#payout {
  width: 100%;
  display: grid;
  grid-template-columns: 228px 1fr;
}

:global(.loader-container .ant-spin.ant-spin-spinning.ant-spin-show-text) {
  position: fixed;
  max-height: unset;
  pointer-events: none;
}
