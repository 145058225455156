@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
/* @font-face {
  font-family: 'Gotham Rounded';
  src: url('./assets/fonts/gothamroundedbold.woff')
    url('./assets/fonts/gothamroundedbook.woff')
    url('./assets/fonts/gothamroundedlight.woff')
    url('./assets/fonts/gothamroundedmedium.woff');
  font-display: swap;
} */

body {
  margin: 0;
  /* font-family: 'Gotham Rounded'; */
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.gem {
  width: 16px;
}

#Login_login__2Px22 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.Login_loginError__2jyWk {
  margin-top: 16px;
  color: #d00;
}

.ProtectedRoute_authenticating-div__3-6jd {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Watson_watson__3mz7W {
  height: 100vh;

  display: grid;
  grid-template-rows: 64px 1fr;
}

#Navbar_navbar__1knG- {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #728ee3;
}

.Navbar_notifications__3rJvo {
  padding: 0 24px 0 0;
}

.Navbar_notifications-icon__1QmHh {
  margin-right: 4px;
}

.Navbar_user__2NY0a {
  padding: 0 12px;
  cursor: pointer;
}

.Navbar_user-dp__jyOTG {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 4px;
}

.Navbar_right__2ztQX {
  display: flex;
  align-items: center;
}

.Navbar_left__1gmo9 {
  padding-left: 8px;
  font-weight: bold;
}

.Navbar_left__1gmo9 a {
  color: #728ee3;
}

.Navbar_down-arrow__2wood {
  width: 16px;
  padding-left: 2px;
}

.Navbar_MenuItems__3avpJ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#Home_home__2Kpyt {
  display: flex;
  flex-direction: column;
}

.Cards_cards__36A_B {
  width: 100%;
  margin-top: 40px;

  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-around;
  grid-row-gap: 40px;
  row-gap: 40px;
}

.Cards_card__1XTZa {
  height: 180px;
  width: 430px;
  border-radius: 40px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}

.ant-card-body {
  height: 100%;
}

.Cards_kyc__15_80,
.Cards_users__3Rs5z,
.Cards_game-schedule__3hXQb,
.Cards_offers-admin__36yCK,
.Cards_player-profiles__eBswU,
.Cards_mtt-admin__1s_LA {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.Cards_multiple-btns-div__2x9q0 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.Cards_text__nNQGe {
  text-align: left;
  font-weight: 600;
}

.Cards_btn-div__yaFYh {
  text-align: right;
  width: 100%;
}

.Cards_main-text__1q44X {
  font-size: 28px;
}

.Cards_sub-text__3IzUy,
.Cards_btn__2Ozkm {
  font-size: 16px;
}

.Cards_btn__2Ozkm {
  font-weight: 600;
}

.Cards_new-indicator__ManCN {
  position: absolute;
  right: 4px;
  top: 4px;
}

.SearchPlayer_search__1Vh3A {
  margin-top: 80px;
}

.SearchPlayer_text__eR4En {
  font-size: 36px;
}

.SearchPlayer_search-input-div__2kMXd {
  margin-top: 16px;
}

.SearchPlayer_search-input__2lkUf {
  width: 600px;
  border-radius: 20px;
}

.ant-input-search-button {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.SearchPlayer_player-not-found__2ueyx {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px auto;
  text-align: left;
}

.ant-alert-message {
  color: #ff4d4f;
}

#Kyc_kyc__dW_OM {
  width: 100%;
  display: grid;
  grid-template-columns: 228px 1fr;
}

.loader-container .ant-spin.ant-spin-spinning.ant-spin-show-text {
  position: fixed;
  max-height: unset;
  pointer-events: none;
}

#Sidebar_sidebar__267gM {
  padding: 40px 0 20px 20px;
  background: #fafafa;
}

.Sidebar_menu-item__2wrv2 {
  font-size: 1rem;
  color: #728ee3;
  text-align: left;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
}

.Sidebar_selected__2pc0w {
  border-right: 4px #728ee3 solid;
}

#OrdersTable_orders-table__2Zq9V {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(4, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.OrdersTable_player-btn__1IEa6 {
  padding: 0;
}

.OrdersTable_value__2Ue2s,
.OrdersTable_head__1FTJA {
  text-align: left;
  word-break: break-all;
}

.OrdersTable_value__2Ue2s {
  padding: 30px 10px 10px 10px;
}

.OrdersTable_head__1FTJA {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.OrdersTable_first__OnJOH {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.OrdersTable_last__1CL-E {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.OrdersTable_title__2FJsJ {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.OrdersTable_lastPage__3AKFd {
  grid-column: 1 / -1;
  padding-top: 40px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

.OrdersTable_loader__12A81 {
  background: red;
}

.OrdersTable_filters__2HLZe {
  grid-column: 1/-1;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

.OrdersTable_header__2Ht9f {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.OrdersTable_applied-filters__1926O {
  grid-column: 1 / -1;
  margin-bottom: 20px;
  text-align: left;
}

.OrdersTable_filter-key__3RSiz {
  font-weight: bold;
}

.FirestoreFilter_firestore-filters__O0oMf {
  padding: 40px;
}

.FirestoreFilter_filters__1VLLj {
  /* display: grid; */
  /* grid-template-columns: auto; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FirestoreFilter_filter-div__QmVAy {
  text-align: left;
  margin-right: 20px;
  min-width: 300px;
}
.FirestoreFilter_last__16amt {
  margin-right: 0;
}

.filter-div .ant-picker,
.filter-div .ant-select {
  min-width: 100%;
}

.FirestoreFilter_actions__j5TN5 {
  text-align: left;
  margin-top: 20px;
}

#PlayerProfiles_watson__28pPs {
  display: grid;
  grid-template-columns: 228px 1fr;
}

#Sidebar_sidebar__tuKy6 {
  padding: 40px 0 20px 20px;
  background: #fafafa;
}

.Sidebar_menu-item__2RWAB {
  font-size: 1rem;
  color: #728ee3;
  text-align: left;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
}

.Sidebar_selected__QpanY {
  border-right: 4px #728ee3 solid;
}

.Sidebar_player-summary__2_Ypa,
.Sidebar_collusion-cases__2mByF,
.Sidebar_xp-and-level__1DIvn {
  margin-bottom: 40px;
}

.Content_coming-soon__3OI41 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#PlayerSummary_player-summary__3L7Et {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  gap: 40px;
  padding: 40px;
  margin-top: 80px;
}

.PlayerSummary_card__2ra13 {
  border-radius: 40px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}

.PlayerSummary_general-details__eHBg1,
.PlayerSummary_wallets__OPdAW,
.PlayerSummary_blocking-details__8YCHZ {
  grid-column: span 2;
}

.PlayerSummary_block-banner__o0YYO {
  grid-column: span 2;
  display: flex;
  position: relative;
  bottom: 70px;
  align-items: center;
  justify-content: center;
  background: rgb(224 32 32 / 0.3);
  border-radius: 8px;
}

.PlayerSummary_block-banner-text__jAk0Z {
  color: #e02020;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  position: relative;
  top: 8px;
}

.PlayerSummary_block-banner-button__r09MF {
  color: #728ee3;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  outline: none;
  border: none;
  background: none;
  margin-left: 10px;
}

.player-summary .ant-card-body {
  padding: 0;
}

#GeneralDetails_general-details__1qFA5 {
  padding: 40px;
}

.GeneralDetails_name__1sQRQ {
  font-size: 28px;
}

.GeneralDetails_address__1M0O- {
  font-size: 16px;
  color: #8b8b8b;
}

.GeneralDetails_details__2fzap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 40px;
}

.GeneralDetails_player-dp__2659P {
  margin-top: -128px;
}

.GeneralDetails_photo__1EJTw {
  height: 160px;
  width: 160px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 80px;
}

.GeneralDetails_location__1BLLM {
  width: 18px;
  padding-bottom: 4px;
  -webkit-filter: invert(50%);
          filter: invert(50%);
}

#BlockFlagReward_block-and-flag__2AFro {
  margin-top: 40px;
}

.BlockFlagReward_block-btn__8hPgI {
  margin-right: 40px;
}

.BlockFlagReward_flag-btn__1Ffmj {
  margin-right: 40px;
}

#ProcessCustomerSupportReward_customer-support-reward__2_upm {
  /* padding: 40px; */
}

.ProcessCustomerSupportReward_key__Ybgd4 {
  color: #8b8b8b;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 20px;
}
.ProcessCustomerSupportReward_key__Ybgd4:nth-child(1) {
  margin-top: 0;
}

.ProcessCustomerSupportReward_value__3-xzv > div {
  width: 100%;
}

.ProcessCustomerSupportReward_error__12n4y {
  margin-top: 20px;
  color: red;
}

.CollusionRow_modalH__3otmj {
  margin-bottom: 16px;
  color: #253162;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.CollusionRow_actionsGroup__1b8sW {
}

.CollusionRow_radio__1UGTJ {
  margin-right: 32px;
}

.CollusionRow_selectTitle__3PAXH {
  width: 160px;
}

.CollusionRow_sectionDivider__1lpPF{
  margin-top: 35px;
}

.CollusionRow_selectDesc__2nF0a {
  width: 500px;
}
.PersonalDetails_personal-details__203uT {
  padding: 40px;
  background: #fafafa;
  border-radius: 24px;
  text-align: left;
}

.PersonalDetails_title__3iApv {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.PersonalDetails_key__26ncP {
  font-size: 0.75rem;
  color: #8b8b8b;
}

.PersonalDetails_value__19VqN {
  font-size: 1rem;
  margin-bottom: 20px;
}

.PersonalDetails_kyc-value__2gqW8 {
  display: flex;
  justify-content: space-between;
}

.PersonalDetails_upi__2aZme {
  margin-top: 10px;
  margin-bottom: 20px;
}

.AppDetails_app-details__2a5At {
  padding: 40px;
  background: #fafafa;
  border-radius: 24px;
  text-align: left;
}

.AppDetails_title__2YLXR {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.AppDetails_key__2jq3r {
  font-size: 0.75rem;
  color: #8b8b8b;
}

.AppDetails_value__415kz {
  font-size: 1rem;
  margin-bottom: 20px;
}

.AppDetails_kyc-value__GwNDC {
  display: flex;
  justify-content: space-between;
}

#Wallets_wallets__1RhUT {
  padding: 40px;
}

.Wallets_title__1ET2G {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Wallets_title__1ET2G > span {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}

.Wallets_title__1ET2G button {
  text-transform: uppercase;
  color: #728ee3;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.Wallets_wallets-list__1M29L,
.Wallets_passes-list__O17g2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}

.Wallets_wallets-list__1M29L {
  margin-bottom: 40px;
}

.Wallets_list-item__1gifk {
  text-align: left;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 24px;
}

.Wallets_key__2xytg {
  font-size: 0.75rem;
  color: #8b8b8b;
  font-weight: bold;
}

.Wallets_value__3o9Bc {
  font-size: 28px;
  font-weight: 300;
}

.move-money-modal .ant-modal {
  width: 768px !important;
}

.move-money-modal .ant-modal-content {
  border-radius: 36px;
}

.move-money-modal .ant-modal-body {
  padding: 60px 72px 44px;
}

.Wallets_modal-title__3OfPA {
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 38px;
  margin-bottom: 42px;
}

.Wallets_modal-label__mGUZK {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 9px;
  margin-top: 27px;
}

.Wallets_modal-label__mGUZK:first-child {
  margin-top: 0;
}

.move-money-modal select,
.move-money-modal textarea {
  cursor: not-allowed;
  width: 100%;
  height: 42px;
  border: 2px solid #728ee3;
  border-radius: 8px;
  color: #728ee3;
  font-weight: bold;
  padding: 8px 16px;
  outline: none;
}

.move-money-modal textarea {
  height: 116px;
  cursor: text;
}

.Wallets_modal-cta__vGpdB {
  margin-top: 80px;
}

.Wallets_modal-confirm__2FimX {
  margin-right: 41px;
  background-color: #728ee3;
  border-color: #728ee3;
  border-radius: 8px;
}

.Wallets_modal-btn__1RS5c {
  font-weight: 600;
  height: 40px;
}

.Wallets_modal-error__Ae5ZP {
  color: red;
  margin-bottom: 8px;
}

#Campaigns_campaign__2Ps5U {
  height: 500px;
  padding: 40px;
}

.Campaigns_title__1N57q {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}

.Campaigns_coming-soon__2Vb0m {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: orange;
}

#Referrals_referrals__2lQSw {
  padding: 40px;
  overflow-y: auto;
}

.Referrals_title__1GopZ {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}

.Referrals_table__365m4 {
  display: grid;
  grid-template-columns: 74px repeat(3, auto);
  text-align: left;
}

.Referrals_th__2zoAV {
  color: #8b8b8b;
  font-size: 0.75rem;
  font-weight: bold;
  background: rgba(216, 216, 216, 0.2);
  padding: 10px;
  margin-top: 20px;
}

.Referrals_td__2qvpA {
  word-break: break-all;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Referrals_dp__3gBdK img {
  width: 54px;
  height: 54px;
  border-radius: 27px;
}

.Referrals_no-referrals__2MbmI {
  padding: 64px;
  font-size: 1.5rem;
  color: #dba;
}

.Referrals_referralAmountReceived__73c75 {
  text-align: left;
  margin-top: 8px;
}

#PlatformDetails_platform-details__2E1wW {
  padding: 40px;
}

.PlatformDetails_title__2d6LP {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}

.PlatformDetails_coming-soon__2mP-a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: orange;
  padding: 64px;
}

#TopPlayedGames_top-played-games__2Pqmy {
  padding: 40px;
  overflow-y: auto;
}

.TopPlayedGames_title__2c-jP {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}
.TopPlayedGames_table__18C9B {
  display: grid;
  grid-template-columns: 84px repeat(2, auto);
  text-align: left;
}

.TopPlayedGames_th__3c9r5 {
  color: #8b8b8b;
  font-size: 0.75rem;
  font-weight: bold;
  background: rgba(216, 216, 216, 0.2);
  padding: 10px;
  margin-top: 20px;
}

.TopPlayedGames_td__1x3MF {
  word-break: break-all;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TopPlayedGames_game-icon__2orNP img {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.TopPlayedGames_no-games__3ReW1 {
  padding: 64px;
  font-size: 1.5rem;
  color: #dba;
}

#AllActivity_timeline__2w3jy {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(4, auto);
}

.AllActivity_title__as7bK {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.AllActivity_value__1-641,
.AllActivity_head__3bkDb {
  text-align: left;
}

.AllActivity_value__1-641 {
  padding: 30px 10px 10px 10px;
  word-break: break-all;
}

.AllActivity_head__3bkDb {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.AllActivity_first__37AIH {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.AllActivity_last__2PZW0 {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.AllActivity_value__1-641 img {
  cursor: pointer;
}

.AllActivity_no-events__3fH0m {
  grid-column: 1 / -1;
  padding: 64px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

.RefundDetails_refund-details__28etp {
  padding: 40px;
}

.RefundDetails_list-item__39eM5 {
  text-align: left;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 24px;
}

.RefundDetails_key__25n5Z {
  font-size: 0.75rem;
  color: #8b8b8b;
  font-weight: bold;
}

.RefundDetails_value__2pjvt {
  font-size: 1.75rem;
  font-weight: 300;
}

.RefundDetails_first__1StKo {
  margin: 20px 0;
}

.RefundDetails_title__17ZmQ {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
}

#BlockingDetails_blocking-details__2nbnH {
  padding: 40px;
}

.BlockingDetails_title__2i361 {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 32px;
}

#BlockingDetails_blocking-details__2nbnH table {
  width: 100%;
  text-align: left;
}

#BlockingDetails_blocking-details__2nbnH th {
  font-size: 0.75rem;
  color: #8b8b8b;
}

#BlockingDetails_blocking-details__2nbnH td {
  font-size: 0.75rem;
  margin-bottom: 20px;
  padding: 16px 0;
}

#PlayerKyc_kyc__35TZ- {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(6, auto);
}

.PlayerKyc_head__1O-Vu {
  font-size: 0.75rem;
  color: #8b8b8b;
  padding: 10px;
  text-align: left;
}

.PlayerKyc_cell__W15D4 {
  padding: 10px;
  text-align: left;
}

.PlayerKyc_Complete__2QuOc {
  color: #45b69b;
}
.PlayerKyc_Manual_Supervision__1-UCP {
  color: #fa9f41;
}
.PlayerKyc_Failed__dSmcR {
  color: #e95656;
}

.PlayerKyc_details__1u7Cb {
  grid-column: span 7;

  padding: 40px;
  text-align: left;
}

.PlayerKyc_wild-upload__ienui {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.PlayerKyc_wild-upload__ienui button {
  padding: 0;
}

.PlayerKyc_no-kyc__1ytFY,
.PlayerKyc_kyc-details-error__3aLBh {
  grid-column: 1 / -1;
  padding: 64px;
  font-size: 1.5rem;
  color: #dba;
}

.Details_title__1lBt8 {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 20px;
}

.Details_checks__FxJWm {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.Details_head__1XNsi {
  padding: 10px;
  background: #fafafa;
  color: #8b8b8b;
  font-size: 0.75rem;
}

.Details_body__2LVxy {
  padding: 10px;
}

.Details_passed__2jSUl {
  color: #45b69b;
}
.Details_failed__1ovRS {
  color: #e95656;
}

.Details_checks__FxJWm {
  margin-bottom: 40px;
}

.Details_images__2KSmp {
  padding: 10px;
}

.Details_images__2KSmp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.Details_document-image__2u_rg {
  height: 220px;
  width: 382px;
}

.Details_details-by__1nyKq {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 40px;
  gap: 40px;
}

.Details_details-by-ocr__ka5IG {
  grid-row: span 2;
}

.Details_details-by-ocr__ka5IG,
.Details_details-by-user__1pP4H,
.Details_accept-reject__CcD0D {
  padding: 40px;
  background: #fafafa;
  border-radius: 24px;
}

.Details_key__Is0JJ {
  color: #8b8b8b;
  text-transform: uppercase;
  font-size: 0.75rem;
}
.Details_value__3qMhR {
  margin-bottom: 20px;
}

.Details_select__HGJdS {
  width: 100%;
}

.Details_accept-reject__CcD0D > div {
  margin-bottom: 40px;
}

.Details_img-btn__3qkHC {
  padding: 0;
}

#AllTransactions_all-transactions__2-RlP {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(4, auto) 300px auto auto;
}

.AllTransactions_value__1PGXy,
.AllTransactions_head__2-I7u {
  text-align: left;
  word-break: break-all;
}

.AllTransactions_value__1PGXy {
  padding: 30px 10px 10px 10px;
}

.AllTransactions_head__2-I7u {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.AllTransactions_first__38TVH {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.AllTransactions_last__24RhX {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.AllTransactions_value__1PGXy img {
  cursor: pointer;
}

.AllTransactions_red__1RqBw {
  color: #e95656;
}
.AllTransactions_green__1xffD {
  color: #45b69b;
}

.AllTransactions_Complete__3PTR5 {
  color: #45b69b;
}
.AllTransactions_Processing__3ghAU,
.AllTransactions_Pending__2llOg {
  color: #fa9f41;
}
.AllTransactions_Failed__sBCyO {
  color: #e95656;
}

.AllTransactions_no-transactions__155S9,
.AllTransactions_lastPage__HVHZS {
  grid-column: 1 / -1;
  padding-top: 40px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

.AllTransactions_title__pApmB {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.all-transactions .ant-spin.ant-spin-spinning.ant-spin-show-text {
  position: fixed;
  max-height: unset;
  pointer-events: none;
}

.Details_order-details__2_iBM {
  grid-column: span 8;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
}

.InstantContestJoin_card__3HeyP {
  height: 370px;
  background: #fafafa;
  border-radius: 24px;
  padding: 40px;
  text-align: left;
  overflow-y: auto;
}

.InstantContestJoin_card__3HeyP:last-child {
  margin-bottom: 20px;
}

.InstantContestJoin_title__eBYaE {
  font-size: 28px;
  font-weight: 300;
}

.InstantContestJoin_key__2q6cu {
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  color: #8b8b8b;
}

.InstantContestJoin_value__1shk_ {
  font-size: 16px;
  word-break: break-all;
}

.InstantContestJoin_wallets__XW_EC {
  display: grid;
  grid-template-columns: repeat(2, auto);
}
.InstantContestJoin_passes__2XArN {
  display: grid;
  grid-template-columns: repeat(4, auto);
}
.InstantContestJoin_refund__2ybeW {
  margin-top: 20px;
}

.InstantContestJoin_th__1AjSu {
  margin-top: 20px;
  background: rgba(216, 216, 216, 0.2);
  padding: 12px;
  color: #8b8b8b;
  font-size: 0.75rem;
}

.InstantContestJoin_td__1M8eg {
  padding: 12px;
}

.InstantContestJoin_process-refund__Zxnb4 {
  padding: 10px;
  margin-top: 40px;
}

.InstantContestJoin_linked-rewards__1LDqA {
  margin-top: 40px;
}

.InstantContestJoin_linked-orders__1JAXC {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.InstantContestJoin_couponDetails__3P-m6 {
  display: grid;
  grid-template-columns: repeat(4, auto);
}

.InstantContestJoin_staggeredDetails__giHmJ > div {
  padding-left: 0;
  padding-right: 0;
}

.StaggeredPreview_StaggeredPreview__3Cv53 {
  padding: 40px;
  border-radius: 8px;
  background-color: #fafafa;
  margin-top: 12px;
  width: unset;
}

.StaggeredPreview_stp-title__1GcSS {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 27px;
  margin-bottom: 28px;
}

.StaggeredPreview_table__27vpg {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
}

.StaggeredPreview_th__2pfhX {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: uppercase;
}

.StaggeredPreview_td__2KxPg {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.StaggeredPreview_amount__3cp8O {
  text-align: right;
}

.StaggeredPreview_separator__12ZTv {
  grid-column: 1/-1;
  background-color: #8b8b8b;
  height: 1px;
  opacity: 0.2;
  margin: 20px 0;
}

.StaggeredPreview_total__13pgq {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
}

.StaggeredPreview_total-text__r4T_m {
  margin-right: 46px;
}

.CFOrder_card__KkB4P {
  height: 370px;
  background: #fafafa;
  border-radius: 24px;
  padding: 40px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
}

.CFOrder_title__CQ2He {
  font-size: 28px;
  font-weight: 300;
}

.CFOrder_key__aM3Hh {
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  color: #8b8b8b;
}

.CFOrder_value__TaYEQ {
  font-size: 16px;
  word-break: break-all;
}

.CFOrder_wallets__3sp_f {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.CFOrder_refund__2fjfL {
  margin-top: 20px;
}

.CFOrder_th__1-zYA {
  margin-top: 20px;
  background: rgba(216, 216, 216, 0.2);
  padding: 12px;
  color: #8b8b8b;
  font-size: 0.75rem;
}

.CFOrder_td__3hXgL {
  padding: 12px;
}

.CFOrder_error__-luK- {
  color: red;
  font-size: 1.1rem;
  margin-top: 20px;
  padding-top: 10px;
}

.CFOrder_close-session__32227 {
  margin-top: 20px;
}

.CFOrder_process-refund__2VV9b {
  padding: 10px;
  margin-top: 40px;
}

.CFOrder_linked-rewards__2infF {
  margin-top: 40px;
}

.CFOrder_linked-orders__16pze {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

#PassTransactions_pass-transactions__1JdBs {
  padding: 40px;
  display: grid;
  grid-template-columns: auto repeat(4, 1fr) 200px 200px auto;
}

.PassTransactions_title__2bavL {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.PassTransactions_value__23OU4,
.PassTransactions_head__22AOu {
  text-align: left;
}

.PassTransactions_value__23OU4 {
  padding: 30px 10px 10px 10px;
  word-break: break-all;
}

.PassTransactions_head__22AOu {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.PassTransactions_first__2Tzx8 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.PassTransactions_last__1KF3e {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.PassTransactions_value__23OU4 img {
  cursor: pointer;
}

.PassTransactions_credit__2wchZ {
  color: #45b69b;
}

.PassTransactions_debit__2LS7B {
  color: #e95656;
}

.PassTransactions_no-pass-transactions__3BpwM {
  grid-column: 1 / -1;
  padding: 64px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

#CFSessions_cf-sessions__2rIPV {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(5, auto);
}

.CFSessions_title__3DoXl {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.CFSessions_th__2VdQx,
.CFSessions_td__1OGjs {
  text-align: left;
  word-break: break-all;
}

.CFSessions_th__2VdQx {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.CFSessions_td__1OGjs {
  padding: 30px 10px 10px 10px;
}

.CFSessions_details__EMkxe {
  grid-column: 1 / -1;
  text-align: left;
  background: #fafafa;
  padding: 40px;
  border-radius: 8px;
}

.CFSessions_key__ZGtYv {
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  color: #8b8b8b;
}

.CFSessions_value__2zNE3 {
  font-size: 16px;
}

#MoonshotLeaderboard_MoonshotLeaderboard__Uo_s0 {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(3, auto) 1fr 1fr auto auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.MoonshotLeaderboard_title__DYZw2 {
  grid-column: 1 / -1;
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.MoonshotLeaderboard_value__2s3t2,
.MoonshotLeaderboard_head__2Orj4 {
  text-align: left;
  word-break: break-all;
}

.MoonshotLeaderboard_value__2s3t2 {
  padding: 30px 10px 10px 10px;
}

.MoonshotLeaderboard_head__2Orj4 {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.MoonshotLeaderboard_first__1QM1B {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.MoonshotLeaderboard_last__2Wk0- {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.MoonshotLeaderboard_value__2s3t2 img {
  cursor: pointer;
}

.MoonshotLeaderboard_red__1T8OR,
.MoonshotLeaderboard_Failed__3ZdtJ {
  color: #e95656;
}

.MoonshotLeaderboard_green__1Sozt,
.MoonshotLeaderboard_Complete__2cPer {
  color: #45b69b;
}

.MoonshotLeaderboard_Processing__odC7j,
.MoonshotLeaderboard_Pending__2uqSN {
  color: #fa9f41;
}

.MSOrderDetails_MSOrderDetails__11yYP {
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
}

.MSOrderDetails_card__1xmZs {
  height: 370px;
  background: #fafafa;
  border-radius: 24px;
  padding: 40px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
}

.MSOrderDetails_title__1NwEn {
  font-size: 28px;
  font-weight: 300;
}

.MSOrderDetails_key__Idmdm {
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  color: #8b8b8b;
}

.MSOrderDetails_value__3v-Vw {
  font-size: 16px;
  word-break: break-all;
}

.MSOrderDetails_wallets__23o8x {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.MSOrderDetails_th__3vBBB {
  margin-top: 20px;
  background: rgba(216, 216, 216, 0.2);
  padding: 12px;
  color: #8b8b8b;
  font-size: 0.75rem;
}

.MSOrderDetails_td__2JEJR {
  padding: 12px;
}

#Collusion_Collusion__gU1J8 {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(6, auto);
}

.Collusion_title__2i0z4 {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.Collusion_currentStatus__35EHW {
  grid-column: 1 / -1;
  text-align: left;
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
}

.Collusion_value__1db6S,
.Collusion_head__3W1Il {
  text-align: left;
}

.Collusion_value__1db6S {
  padding: 30px 10px 10px 10px;
  word-break: break-all;
}

.Collusion_head__3W1Il {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.Collusion_first__FmkXu {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.Collusion_last__3x3lM {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Collusion_blockBtn__2g34d {
  margin-right: 40px;
}

.Collusion_closeBtn__3x6Eg {
  margin-left: 40px;
}

.Collusion_noCases__2ZbZL {
  grid-column: 1 / -1;
  padding: 64px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

#GameHistory_GameHistory__aHdUJ {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(7, auto);
}

.GameHistory_title__3arhq {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.GameHistory_value__3z-UC,
.GameHistory_head__278w5 {
  text-align: left;
}

.GameHistory_value__3z-UC {
  padding: 30px 10px 10px 10px;
  word-break: break-all;
}

.GameHistory_head__278w5 {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.GameHistory_RightArrow__1FhTV {
  cursor: pointer;
}

.GameHistory_StatusComplete__1ndgd {
  color: green;
}

.GameHistory_StatusOngoing__kySPo {
  color: orange;
}

.GameHistory_Game__Oc6Yo {
  display: flex;
  align-items: center;
}

.GameHistory_GameIcon__2ODhn img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.GameHistory_GameName__KKKau {
  margin-left: 20px;
}

#DepositOffers_deposit-offers__1DXu5 {
  display: grid;
  grid-template-columns: 228px 1fr;
}

#Sidebar_sidebar__3j9Fr {
  padding: 40px 0 20px 20px;
  background: #fafafa;
}

.Sidebar_menu-item__3t25z {
  font-size: 1rem;
  color: #728ee3;
  text-align: left;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
}

.Sidebar_selected__2my-i {
  border-right: 4px #728ee3 solid;
}

.Sidebar_player-summary__2Xp-G,
.Sidebar_cf-sessions__1bUvw,
.Sidebar_xp-and-level__28cpI {
  margin-bottom: 40px;
}

#OffersList_offers-list__33bMq {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(8, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.OffersList_header__3odPD {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.OffersList_title__PCIri {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.OffersList_value__2WDqR,
.OffersList_head__1lzED {
  text-align: left;
  word-break: break-all;
}

.OffersList_head__1lzED {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
  font-weight: 600;
}

.OffersList_first__3Od5X {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.OffersList_last__1oLys {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.OffersList_no-offers__bEv6P {
  grid-column: 1 / -1;
  padding: 64px;
  text-align: center;
  font-size: 20px;
  color: red;
}

.OfferRow_value__28QhQ {
  padding: 30px 10px 10px 10px;
  text-align: left;
  word-break: break-all;
}

.OfferRow_quick-actions__24NWl {
  padding: 30px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
}

.OfferRow_quick-actions__24NWl svg {
  cursor: pointer;
}

.OfferDetails_offer-details__2Trdu {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
}

.OfferDetails_card__1J4cX {
  height: 370px;
  background: #fafafa;
  border-radius: 24px;
  padding: 40px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
}

.OfferDetails_title__3-rdO {
  font-size: 28px;
  font-weight: 300;
}

.OfferDetails_key__2njHb {
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  color: #8b8b8b;
}

.OfferDetails_value__2sVY5 {
  font-size: 16px;
  word-break: break-all;
}

.OfferDetails_attributes-list__1yM-R {
  display: flex;
  overflow: auto;
}


#CreateOffer_deposit-offers__3jPMp {
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.CreateOffer_title__2frKb {
  font-size: 36px;
  font-weight: 300;
  text-align: left;
  margin-top: 40px;
  grid-column: 1 / span 2;
}

.CreateOffer_subtitle__3BANB {
  font-size: 28px;
  margin-top: 40px;
  font-weight: 300;
  text-align: start;
}

.CreateOffer_create-offer-form__1wEfp {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.CreateOffer_create-offer-form__1wEfp > * {
  width: 322px;
}

.CreateOffer_create-offer-form__1wEfp > input,
.CreateOffer_create-offer-form__1wEfp > select,
.CreateOffer_create-offer-form__1wEfp > textarea,
.CreateOffer_create-offer-form__1wEfp > .ant-picker,
.CreateOffer_details-array__1kNkG > input {
  width: 322px;
  border: 2px solid #728ee3;
  border-radius: 8px;
  padding: 8px;
}

.CreateOffer_details-array__1kNkG > input {
  width: 300px;
  margin-top: 4px;
}

.CreateOffer_details-array__1kNkG > .ant-btn {
  padding-right: 0;
  font-weight: bold;
}

.CreateOffer_create-offer-form__1wEfp > span {
  margin-top: 20px;
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.CreateOffer_create-offer-btn__1Fw9U {
  margin-top: 16px;
  padding: 8px;
  cursor: pointer;
  border: none;
  background: #3390ff;
  color: white;
  font-weight: bold;
}

.CreateOffer_create-offer-btn__1Fw9U:disabled,
.CreateOffer_create-audience-btn__1a7SE:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.CreateOffer_error__3aEJw {
  margin-top: 20px;
  padding: 4px;
  color: red;
  font-weight: bold;
}

.CreateOffer_add-detail-btn__1GC7C {
  padding-left: 0;
  margin-top: 4px;
}

.CreateOffer_field-error__31Rw2 {
  color: red;
}

#CSVUpload_CSVUpload__3CfBo {
  padding: 40px;
}

.CSVUpload_header__1HCnC {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.CSVUpload_title__3_gXI {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.CSVUpload_cta__L-Kad {
  display: flex;
}

.CSVUpload_cta__L-Kad input {
  height: -webkit-max-content;
  height: max-content;
  border: navajowhite;
}

.CSVUpload_table__1pKkg {
  display: grid;
  grid-template-columns: repeat(5, auto);
  margin-top: 50px;
}

.CSVUpload_th__ni2mZ {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-transform: uppercase;
  text-align: left;
}

.CSVUpload_td__2-Y1U {
  padding: 36px 0;
  text-align: left;
}

.CSVUpload_download__2ZuEZ {
  cursor: pointer;
}

.CSVUpload_download__2ZuEZ svg {
  width: 24px;
  height: 24px;
  color: #728ee3;
}

#UploadPopup_UploadPopup__2LJgo {
  position: fixed;
  bottom: 0;
  right: 40px;
  width: 400px;
  text-align: left;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background: white;
  z-index: 1;
}

.UploadPopup_header__1rblT {
  color: #ffffff;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.375rem;
  background: #000;
  padding: 8px 16px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UploadPopup_content__1Ltw0 {
  padding: 24px 16px;
}

.UploadPopup_filename__1HI9v,
.UploadPopup_errorHeader__mBM1w {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.375rem;
  margin-bottom: 25px;
}

.UploadPopup_errorHeader__mBM1w {
  margin-bottom: 8px;
  color: #e02020;
}

.UploadPopup_offersCount__2CFxv {
  color: #8b8b8b;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.0625rem;
  text-transform: uppercase;
  margin-bottom: 4px;
}

progress {
  width: 100%;
  margin-bottom: 20px;
}

.UploadPopup_errorHelp__JqIn1 {
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.0625rem;
  margin-bottom: 25px;
}

.UploadPopup_download__3G_bm,
.UploadPopup_download__3G_bm a,
.UploadPopup_successHeader__9c93g {
  color: #728ee3;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.375rem;
}

.UploadPopup_downloadIcon__2alTj {
  margin-right: 10px;
}

.UploadPopup_close__37IBb {
  cursor: pointer;
}

.UploadPopup_successHeader__9c93g {
  color: #45b69c;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UploadPopup_filenameSuccess__L32Z1 {
  color: #45b69c;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.0625rem;
}

.UploadPopup_checkSvg__3GMj9 svg {
  height: 34px;
  width: 34px;
  color: #00b440;
}

#AudienceService_audience-service__1oFwU {
  padding: 40px 32px;
}

.CreateAudience_title__2fSDv {
  font-size: 36px;
  font-weight: 300;
  text-align: left;
  margin-top: 40px;
  grid-column: 1 / span 2;
}

.CreateAudience_subtitle__3eliB {
  font-size: 28px;
  margin: 0 0 20px 0;
  font-weight: 300;
  text-align: start;
}

.CreateAudience_create-audience-form__1sIDt {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.CreateAudience_create-audience-form__1sIDt > * {
  width: 322px;
}

.CreateAudience_create-audience-form__1sIDt > input,
.CreateAudience_create-audience-form__1sIDt > select,
.CreateAudience_create-audience-form__1sIDt > textarea,
.CreateAudience_create-audience-form__1sIDt > .ant-picker,
.CreateAudience_attribute__2iUgT > input,
.CreateAudience_attribute__2iUgT > select {
  width: 322px;
  border: 2px solid #728ee3;
  border-radius: 8px;
  padding: 8px;
}

.CreateAudience_attribute__2iUgT > select:disabled {
  background: lightgray;
  border: 2px solid lightgray;
}

.CreateAudience_create-audience-form__1sIDt > span,
.CreateAudience_attribute__2iUgT span {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  /* display: block; */
}

.CreateAudience_create-audience-btn__33jTT {
  margin-top: 20px;
  padding: 8px;
  cursor: pointer;
  border: none;
  background: #3390ff;
  color: white;
  font-weight: bold;
}

.CreateAudience_create-audience-btn__33jTT:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.CreateAudience_error__1dQVC {
  margin-top: 20px;
  padding: 4px;
  color: red;
  font-weight: bold;
}

.CreateAudience_attribute__2iUgT {
  margin-top: 16px;
  padding-bottom: 16px;
  /* border-bottom: 1px #ccc solid; */
}

.CreateAudience_remove-attribute__17LjA {
  background: transparent;
  color: #728ee3;
  border: none;
  /* width: 32px; */
  font-size: 1.5rem;
}

.CreateAudience_add-attribute__3KdcP {
  background: transparent;
  color: #728ee3;
  border: none;
}

.CreateAudience_separator__1i7WE {
  margin-bottom: 20px;
}

.CreateAudience_field-error__-dx4s {
  color: red;
}

#Payout_payout__2jH5z {
  width: 100%;
  display: grid;
  grid-template-columns: 160px 1fr;
}

.loader-container .ant-spin.ant-spin-spinning.ant-spin-show-text {
  position: fixed;
  max-height: unset;
  pointer-events: none;
}

#Sidebar_sidebar__3_7H9 {
  padding: 40px 0 20px 20px;
  background: #fafafa;
}

.Sidebar_menu-item__3kYgJ {
  font-size: 1rem;
  color: #728ee3;
  text-align: left;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
}

.Sidebar_selected__3e_zG {
  border-right: 4px #728ee3 solid;
}

#OrdersTable_orders-table__wwY1v {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(6, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.OrdersTable_player-btn__3d_lA {
  padding: 0;
}

.OrdersTable_value__23BF2,
.OrdersTable_head__5qRD6 {
  text-align: left;
  word-break: break-all;
}

.OrdersTable_value__23BF2 {
  padding: 30px 10px 10px 10px;
}

.OrdersTable_head__5qRD6 {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.OrdersTable_first__2txBz {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.OrdersTable_last__xydjH {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.OrdersTable_title__FVbrY {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.OrdersTable_lastPage__zvD6M {
  grid-column: 1 / -1;
  padding-top: 40px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

.OrdersTable_loader__3aHxe {
  background: red;
}

.OrdersTable_filters__36aXS {
  grid-column: 1/-1;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

.OrdersTable_header__2fvf8 {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.OrdersTable_applied-filters__3pTW_ {
  grid-column: 1 / -1;
  margin-bottom: 20px;
  text-align: left;
}

.OrdersTable_filter-key___n0dM {
  font-weight: bold;
}

#CfSession_payout__sGLHb {
  width: 100%;
  display: grid;
  grid-template-columns: 228px 1fr;
}

.loader-container .ant-spin.ant-spin-spinning.ant-spin-show-text {
  position: fixed;
  max-height: unset;
  pointer-events: none;
}

#Sidebar_sidebar__37BGK {
  padding: 40px 0 20px 20px;
  background: #fafafa;
}

.Sidebar_menu-item__107rs {
  font-size: 1rem;
  color: #728ee3;
  text-align: left;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
}

.Sidebar_selected__MQ1gN {
  border-right: 4px #728ee3 solid;
}

#OrdersTable_orders-table__bEFvg {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(6, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.OrdersTable_player-btn__2zIl4 {
  padding: 0;
}

.OrdersTable_value__2MiCl,
.OrdersTable_head__1kk4h {
  text-align: left;
  word-break: break-all;
}

.OrdersTable_value__2MiCl {
  padding: 30px 10px 10px 10px;
}

.OrdersTable_head__1kk4h {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.OrdersTable_first__1tTc6 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.OrdersTable_last__3Vdp5 {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.OrdersTable_title__3YHOE {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.OrdersTable_lastPage__2rfVI {
  grid-column: 1 / -1;
  padding-top: 40px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

.OrdersTable_loader__JrZnw {
  background: red;
}

.OrdersTable_filters__6IEj7 {
  grid-column: 1/-1;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

.OrdersTable_header__22nmh {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.OrdersTable_applied-filters__35gKr {
  grid-column: 1 / -1;
  margin-bottom: 20px;
  text-align: left;
}

.OrdersTable_filter-key__2pYKH {
  font-weight: bold;
}

#Deposit_deposit__1x_kD {
  width: 100%;
  display: grid;
  grid-template-columns: 228px 1fr;
}

.loader-container .ant-spin.ant-spin-spinning.ant-spin-show-text {
  position: fixed;
  max-height: unset;
  pointer-events: none;
}

#Sidebar_sidebar__2o6xE {
  padding: 40px 0 20px 20px;
  background: #fafafa;
}

.Sidebar_menu-item__2QEa- {
  font-size: 1rem;
  color: #728ee3;
  text-align: left;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
}

.Sidebar_selected__3H9tX {
  border-right: 4px #728ee3 solid;
}

#OrdersTable_orders-table__3rX1X {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(7, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.OrdersTable_player-btn__19X4b {
  padding: 0;
}

.OrdersTable_value__2zJXV,
.OrdersTable_head__1DQtN {
  text-align: left;
  word-break: break-all;
}

.OrdersTable_value__2zJXV {
  padding: 30px 10px 10px 10px;
}

.OrdersTable_head__1DQtN {
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
}

.OrdersTable_first__1bqfr {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.OrdersTable_last__23LPx {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.OrdersTable_title__1sYUF {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.OrdersTable_lastPage__2DCaV {
  grid-column: 1 / -1;
  padding-top: 40px;
  color: #ddbbaa;
  font-size: 1.5rem;
}

.OrdersTable_loader__2J62z {
  background: red;
}

.OrdersTable_filters__2aFlI {
  grid-column: 1/-1;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

.OrdersTable_header__1y0DI {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.OrdersTable_applied-filters__czoRe {
  grid-column: 1 / -1;
  margin-bottom: 20px;
  text-align: left;
}

.OrdersTable_filter-key__ogrUo {
  font-weight: bold;
}

#CollusionGroup_CollusionGroup__2qxLd {
  padding: 0 32px 32px;
}

.CollusionGroup_title__glqIl {
  font-size: 36px;
  font-weight: 300;
  text-align: left;
  margin-top: 40px;
  grid-column: 1 / span 2;
}

.CollusionGroup_cta__vTkq1 {
  margin-top: 32px;
  text-align: left;
}

.CollusionGroup_error__pa6YG,
.CollusionGroup_success__29w5q {
  margin-bottom: 8px;
  font-weight: 600;
}

.CollusionGroup_error__pa6YG {
  color: red;
}

.CollusionGroup_success__29w5q {
  color: green;
}

#StaggeredRewads_staggeredRewards__1cDpt {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}
#StaggeredTemplateList_rewardsList__2_jQi {
  padding: 40px;
  display: grid;
  grid-template-columns: 50px repeat(3, auto);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.StaggeredTemplateList_header__JzL2j {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.StaggeredTemplateList_title__2QjK9 {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 20px;
}

.StaggeredTemplateList_head__39_Z0 {
  text-align: left;
  word-break: break-all;
  background: rgba(216, 216, 216, 0.2);
  color: #8b8b8b;
  padding: 10px;
  font-weight: 600;
}

.StaggeredTemplateList_first__IqIxN {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.StaggeredTemplateList_noRewards__sXIW6 {
  grid-column: 1 / -1;
  padding: 64px;
  text-align: center;
  font-size: 20px;
  color: red;
}

.StaggeredRewardTemplateRow_value__2M72B {
  text-align: left;
  word-break: break-all;
  padding: 30px 10px 10px 10px;
}

.StaggeredDetails_StaggeredDetails__2cBTa {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  gap: 20px;
}

.StaggeredDetails_card__16T6M {
  height: 370px;
  background: #fafafa;
  border-radius: 24px;
  padding: 40px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
}

.StaggeredDetails_title__3LXaz {
  font-size: 28px;
  font-weight: 300;
}

