.title {
  font-size: 36px;
  font-weight: 300;
  text-align: left;
  margin-top: 40px;
  grid-column: 1 / span 2;
}

.subtitle {
  font-size: 28px;
  margin: 0 0 20px 0;
  font-weight: 300;
  text-align: start;
}

.create-audience-form {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.create-audience-form > * {
  width: 322px;
}

.create-audience-form > input,
.create-audience-form > select,
.create-audience-form > textarea,
.create-audience-form > :global(.ant-picker),
.attribute > input,
.attribute > select {
  width: 322px;
  border: 2px solid #728ee3;
  border-radius: 8px;
  padding: 8px;
}

.attribute > select:disabled {
  background: lightgray;
  border: 2px solid lightgray;
}

.create-audience-form > span,
.attribute span {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  /* display: block; */
}

.create-audience-btn {
  margin-top: 20px;
  padding: 8px;
  cursor: pointer;
  border: none;
  background: #3390ff;
  color: white;
  font-weight: bold;
}

.create-audience-btn:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.error {
  margin-top: 20px;
  padding: 4px;
  color: red;
  font-weight: bold;
}

.attribute {
  margin-top: 16px;
  padding-bottom: 16px;
  /* border-bottom: 1px #ccc solid; */
}

.remove-attribute {
  background: transparent;
  color: #728ee3;
  border: none;
  /* width: 32px; */
  font-size: 1.5rem;
}

.add-attribute {
  background: transparent;
  color: #728ee3;
  border: none;
}

.separator {
  margin-bottom: 20px;
}

.field-error {
  color: red;
}
