#sidebar {
  padding: 40px 0 20px 20px;
  background: #fafafa;
}

.menu-item {
  font-size: 1rem;
  color: #728ee3;
  text-align: left;
  margin-bottom: 20px;
  cursor: pointer;
  outline: none;
}

.selected {
  border-right: 4px #728ee3 solid;
}

.player-summary,
.collusion-cases,
.xp-and-level {
  margin-bottom: 40px;
}
